import React from 'react'
import styles from './Header.module.scss'

import logo from '../../assets/LogoProdubanco.svg'

export const Header = () => {
  return (
    <div className={styles.container}>
        <div className={styles.containerMax}>
            <img src={logo} alt="logo-produbanco" />
        </div>
    </div>
  )
}
