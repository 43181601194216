// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_container__4L7Vo {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.App_container__4L7Vo .App_header__PKNxv {
  flex: 0 1;
  width: 100%;
}
.App_container__4L7Vo .App_body__00BrA {
  flex: 1 1;
  width: 100%;
  height: 100%;
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 2cm;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AACF;AACE;EACE,SAAA;EACA,WAAA;AACJ;AAEE;EACE,SAAA;EACA,WAAA;EACA,YAAA;AAAJ;;AAIA;EACE;IACE,SAAA;EADF;EAIA;IACE,WAAA;EAFF;AACF","sourcesContent":[".container {\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow-x: hidden;\n\n  .header {\n    flex: 0;\n    width: 100%;\n  }\n\n  .body {\n    flex: 1;\n    width: 100%;\n    height: 100%;\n  }\n}\n\n@media print {\n  @page {\n    margin: 0;\n  }\n\n  body {\n    margin: 2cm;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `App_container__4L7Vo`,
	"header": `App_header__PKNxv`,
	"body": `App_body__00BrA`
};
export default ___CSS_LOADER_EXPORT___;
