import React from 'react'
import styles from './Footer.module.scss'

export const Footer = () => {
  return (
    <div className={styles.container}>
        <div className={styles.containerMax}>
            <p>Si tienes problemas par ingresar, comunícate al: <b>1700 123 123 o (02) 2996 200.</b></p>
            <p>Version 1.0.0</p>
        </div>
    </div>
  )
}
