export const Routes = {
    login: {
        route: "/",
    },
    requisitos: {
        route: "/requisitos",
        wordKey: "Requisitos",
    },
    success: {
        route: "/success",
        wordKey: "Login exitoso",
    },
};
