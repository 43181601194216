import React, { useEffect, useRef, useState } from "react";
import styles from "./Login.module.scss";
import QRCode from "react-qr-code";
import CircularIndeterminate from "../../components/utils/spinner/Spinner";
import { LinearProgress } from "@mui/material";
// Services
import { loginQREvent, loginQRGet, loginQRMerchant } from "../../services/login";
import { checkAuth } from "../../services/checkAuth";
// Recoil
import {
    userCredentials,
    snackbarData,
    userInfo,
} from "../../recoilState/GlobalState";
import { useSetRecoilState } from "recoil";
import { Routes } from "../../consts/Routes-App";
import { useLocation, useNavigate } from "react-router-dom";
import img from "../../assets/imgProdu.svg"
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import ReplayIcon from '@mui/icons-material/Replay';

export default function Login() {
    const setCredentials = useSetRecoilState(userCredentials);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setInfo = useSetRecoilState(userInfo);
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(false);
    const [UUID, setUUID] = useState();
    const [loading, setLoading] = useState(false);
    const [expirado, setExpirado] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const [coord, setCoord] = useState({});
    const [qr, setQR] = useState(null);
    const [progress, setProgress] = useState(0);
    const intentos = useRef(0);
    const from =
        Routes.success.route;

    async function getQR(e) {
        setUUID(null);
        setQR(null);
        setError(false);
        if (e) e.preventDefault();
        setExpirado(false);
        setLoading(true);
        const { result } = await loginQRGet({
            sesionLatitud: coord.lat.toFixed(6),
            sesionLongitud: coord.lng.toFixed(6),
        });
        const data = result[0]
        setProgress(0);
        setQR(data.codigoQR);
        const url = new URL(data.codigoQR)
        const info = url.searchParams.get("codigoQR")
        setUUID(info);
        setLoading(false);
    }

    const handleRequisitos = () => {
        navigate(Routes.requisitos.route);
    }

    useEffect(() => {
        sessionStorage.clear();
        if (checkAuth()) {
            navigate(from, { replace: true });
            return;
        }
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoord({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                });
            },
            (error) =>
                setSnackbarInfo({
                    message: "Error de localización",
                    open: true,
                    severity: "error",
                })
        );
        const timer = setInterval(() => {
            setProgress((oldProgress) =>
                oldProgress + 140 / 300 >= 100
                    ? 100
                    : oldProgress + ((140 / 300) * 100) / 140
            );
        }, (140 / 300) * 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);
    useEffect(() => {
        if (coord.lat && coord.lng) getQR();
    }, [coord]);

    useEffect(() => {
        if (UUID) {
            (async function getQREvent() {
                const { result: result, status } = await loginQREvent({
                    codigoQR: UUID,
                });
                // console.log(merchants);
                if (status.code === 0) {
                    intentos.current++;
                    setUUID(null);
                    // if (intentos.current > 2) {
                    //     setExpirado(true);
                    // } else {
                        getQR();
                    // }
                    return;
                }
                if (status.code === 1) {
                            // sessionStorage.setItem(
                            //     "hooliToken",
                            //     credentials[0].hooliToken
                            // );
                            // sessionStorage.setItem(
                            //     "sessionId",
                            //     credentials[0].sessionId
                            // );
                            sessionStorage.setItem(
                                "userName",
                                result[0].usuarioNombres
                            );
                            sessionStorage.setItem(
                                "userLastName",
                                result[0].usuarioApellidos
                            );
                            sessionStorage.setItem(
                                "userDoc",
                                result[0].usuarioNroDeDocumentoX
                            );
                            // const newCredentials = {
                            //     ...credentials[0],
                            //     ...result[0],
                            // };
                            // setCredentials(newCredentials);
                            // const userData = await usuarioMinimosGet(newCredentials);
                            // const { result: merchantData } = await merchantLogoGet({}, newCredentials);
                            // setInfo({ ...merchantData[0], ...userData });
                            navigate(from, { replace: true });
                    }
            })();
        }
    }, [UUID]);
    return (
    <div className={styles.container}>
        <Header/>
        <main className={styles.root}>
            <div className={styles.leftContainer}>
                <div>
                    <div className={styles.titleContainer}>
                        <h4>Hola, te damos la bienvenida</h4>
                        <p>
                            Para ingresar a tu cuenta Produbanco escanea el QR desde tu app o con la cámara de tu teléfono
                        </p>
                    </div>
                    <div className={styles.containerQr}>
                    {UUID && merchants.length === 0 && !loading ? <QRCode className={styles.qr} value={qr} /> : <CircularIndeterminate />}
                    </div>
                    <div className={styles.containerProgress}>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            style={{
                                width: "260px",
                                height: "6px",
                                borderRadius: "10px",
                                backgroundColor: "#D9D9D9",
                            }}
                            color="secondary"
                            />
                            <div className={styles.button} onClick={() => getQR()}>
                                <ReplayIcon/>
                            </div>
                    </div>
                <div className={styles.containerRegister}>
                    <div className={styles.containerInfo}>
                        <h5>¿Quieres crear tu cuenta Produbanco?</h5>
                        <p>Haz click en el siguiente botón y sigue los pasos</p>
                    </div>
                    <button
                        className={styles.button}
                        onClick={handleRequisitos}
                    >
                        Registrate aqui
                    </button>
                </div>
                </div>
            </div>
            <div className={styles.rightContainer}>
                <img src={img} alt="Persona escaneando QR" />
            </div>
            </main>
            <Footer/>
    </div>
    );
}
