import { Routes } from "../consts/Routes-App";
export const logout = () => {
    sessionStorage.clear();
    window.location.assign(Routes.login.route);
};

export const sessionStoragePersistRecoil =
    (key) =>
    ({ setSelf, onSet }) => {
        const savedValue = sessionStorage.getItem(key);
        if (savedValue != null) {
            setSelf(JSON.parse(savedValue));
        }

        onSet((newValue, _, isReset) => {
            isReset
                ? sessionStorage.removeItem(key)
                : sessionStorage.setItem(key, JSON.stringify(newValue));
        });
    };
