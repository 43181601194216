import axiosPost from "../axiosPost";

export const loginWebRequest = async (dni, lat, lng) => {
    const body = {
        service: "LogInWebRequest",
        params: {
            usuarioNroDeDocumento: dni,
            sesionLatitud: lat,
            sesionLongitud: lng,
        },
    };
    const result = await axiosPost(body);

    return result.status;
};
