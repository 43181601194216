import axiosPost from "../axiosPost";

export const loginQRMerchantUsuarioGet = async (credentials) => {
    const body = {
        service: "MerchantUsuarioGet",
        params: {},
    };

    const result = await axiosPost(body, credentials);
    return result.result;
};
