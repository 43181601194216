// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_container__TsUVY {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ECECEC;
  height: 80px;
  z-index: 1;
}
.Header_container__TsUVY .Header_containerMax__BvEGf {
  max-width: 1440px;
  width: -webkit-fill-available;
  margin-inline: 78px;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;EACA,gCAAA;EACA,YAAA;EACA,UAAA;AACJ;AAAI;EACI,iBAAA;EACA,6BAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAER","sourcesContent":[".container {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    border-bottom: 1px solid #ECECEC;\n    height: 80px;\n    z-index: 1;\n    .containerMax{\n        max-width: 1440px;\n        width: -webkit-fill-available;\n        margin-inline: 78px;\n        display: flex;\n        align-items: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Header_container__TsUVY`,
	"containerMax": `Header_containerMax__BvEGf`
};
export default ___CSS_LOADER_EXPORT___;
