import { RequestServiceGet } from "./RequestServiceGet";

export const getUserCredentials = async () => {
    if (
        sessionStorage.getItem("hooliToken") &&
        sessionStorage.getItem("sessionId")
    ) {
        return {
            hooliToken: sessionStorage.getItem("hooliToken"),
            sessionId: sessionStorage.getItem("sessionId"),
            merchantId: sessionStorage.getItem("merchantId"),
            merchantRZ: sessionStorage.getItem("merchantRZ"),
            merchantNombre: sessionStorage.getItem("merchantNombre"),
            merchantCUIT: sessionStorage.getItem("merchantCUIT"),
        };
    }

    return null;

    // let userCredentials;
    // try {
    //     userCredentials = await RequestServiceGet({
    //         service: "TemporalSession",
    //         params: {
    //             merchantId: 7,
    //         },
    //     });
    // } catch (err) {
    //     console.log(`Error: ${err}`);
    // }

    // if (!userCredentials || !(userCredentials?.length > 0)) {
    //     console.log(`Fetch failed`);
    // }

    // sessionStorage.setItem("hooliToken", userCredentials[0].hooliToken);
    // sessionStorage.setItem("sessionId", userCredentials[0].sessionId);
    // sessionStorage.setItem("merchantId", 7);
    // sessionStorage.setItem("merchantRZ", "Neuquén");
    // sessionStorage.setItem("merchantNombre", "Neuquén");
    // sessionStorage.setItem("merchantCUIT", "30-71512361-2");

    // const newCredentials = {
    //     ...userCredentials[0],
    //     merchantId: 7,
    //     merchantRZ: "Neuquén",
    //     merchantNombre: "Neuquén",
    //     merchantCUIT: "30-71512361-2",
    // };

    // return newCredentials;
};
