import React, { useEffect, useState } from "react";
import { Routes as Switch, Route, useLocation, useNavigate } from "react-router-dom";

// import { useRecoilState, useSetRecoilState } from "recoil";
import { userInfo, userCredentials, rioNegroServices, snackbarData, userServices } from "./recoilState/GlobalState";

import { getUserCredentials } from "./services/getUserCredentials";
import { checkAuth } from "./services/checkAuth";
// import { usuarioMinimosGet } from "./services/services-mia";

import { CircularIndeterminate } from "./components";

import {
    Login,
} from "./pages";

import { Routes as ROUTES } from "./consts/Routes-App";

import s from "./App.module.scss"
import { subscribe, unsubscribe } from "./utils/eventManager.js";
import { permisosCheck } from "./services/permisosCheck";
import { useRecoilState, useSetRecoilState } from "recoil";
import Success from "./pages/success/Success.jsx";
import Requisitos from "./pages/requisitos/Requisitos.jsx";

function App() {
  const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [credentials, setCredentials] = useRecoilState(userCredentials);
    const [permisos, setPermisos] = useRecoilState(userServices);
    const [routeError, setRouteError] = useState(false);
    // const setSnackBarInfo = useSetRecoilState(snackbarData);
    const setInfo = useSetRecoilState(userInfo);
    const setServices = useSetRecoilState(rioNegroServices);
    const navigate = useNavigate();

    const location = useLocation();

    const handleClickModal = () => {
        setIsOpen(false);
        if (routeError) {
            navigate(routeError)
        }
    }

    useEffect(() => {
        setLoading(true);
        (async () => {
            const newCredentials = await getUserCredentials();
            // console.log("Entre", newCredentials)
            if (newCredentials) {
                setCredentials(newCredentials);
                // const userData = await usuarioMinimosGet(newCredentials);
                // const { result: merchantData } = await merchantLogoGet({}, newCredentials);
                // setInfo({ ...merchantData[0], ...userData });
                // const { result: servicesData } = await RNServicioGet({}, newCredentials);
                // setServices([...servicesData]);
            }
            setIsAuth(checkAuth());
            setLoading(false);
        })();
    }, [setCredentials, setInfo]);

    useEffect(() => {
        if (loading) return;
        setIsAuth(checkAuth());
    }, [credentials, loading]);
    // console.log(permisos)
    useEffect(() => {
        const handleErrorServer = (data) => {
            // setSnackBarInfo({
            //     message: data.message,
            //     severity: "warning",
            //     open: true,
            // });
        };
        const handleSessionExpired = (data) => {
            setRouteError(ROUTES.login.route);
            setIsOpen(true);
        };
        subscribe("serverError", handleErrorServer);
        subscribe("sessionExpired", handleSessionExpired);
        return () => {
            unsubscribe("serverError", handleErrorServer);
            unsubscribe("sessionExpired", handleSessionExpired);
        };
    }, []);
    if (loading) return <CircularIndeterminate />;

  return (
    <div className={s.container}>
        {/* <div className={setIsAuth.header}>
            {location.pathname !== ROUTES.login.route && <Header />}

        </div> */}
        <div className={s.body}>
            {/* <SnackBar /> */}
            {/* <ModalError isOpen={isOpen} setIsOpen={setIsOpen} handleClick={handleClickModal} title={"Ups..."} text={"Su sesión ha expirado"} /> */}
            <Switch>
                <Route path={ROUTES.login.route} exact element={<Login />} />
                <Route path={ROUTES.success.route} exact element={<Success />} />
                <Route path={ROUTES.requisitos.route} exact element={<Requisitos />} />
            </Switch>
        </div>
    </div>
);
}

export default App;
