// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_container__iICw7 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #00633F;
  height: 70px;
  color: #fff;
  z-index: 1;
}
.Footer_container__iICw7 .Footer_containerMax__VBMwx {
  max-width: 1440px;
  margin-inline: 78px;
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Footer_container__iICw7 .Footer_containerMax__VBMwx p:last-child {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;AACJ;AAAI;EACI,iBAAA;EACA,mBAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAER;AADQ;EACI,eAAA;AAGZ","sourcesContent":[".container {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    background-color: #00633F;\n    height: 70px;\n    color: #fff;\n    z-index: 1;\n    .containerMax{\n        max-width: 1440px;\n        margin-inline: 78px;\n        width: -webkit-fill-available;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        p:last-child{\n            font-size: 14px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Footer_container__iICw7`,
	"containerMax": `Footer_containerMax__VBMwx`
};
export default ___CSS_LOADER_EXPORT___;
