import axiosPost from "../axiosPost";

export const loginWebCheck = async (dni, webToken, lat, lng) => {
    const body = {
        service: "LogInWebCheck",
        params: {
            usuarioNroDeDocumento: dni,
            usuarioLogInWebToken: webToken,
            sesionLatitud: lat,
            sesionLongitud: lng,
        },
    };
    const result = await axiosPost(body);

    return result;
};
